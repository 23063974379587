<template>
  <b-card>
    <b-form @submit.prevent="save">
      <b-row class="mt-1">
        <b-col cols="12">
          <h4>Informações gerais</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="8">
          <b-form-group label="Nome da empresa" label-for="companyName">
            <b-form-input
              id="companyName"
              v-model="companyName"
              :class="{ 'is-invalid': v$.companyName.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.companyName.$invalid">
                Você deve informar a razão social da empresa
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="CNPJ" label-for="cnpj">
            <b-form-input
              id="cnpj"
              v-mask="'##.###.###/####-##'"
              v-model="cnpj"
              :class="{ 'is-invalid': v$.cnpj.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.cnpj.valid.$invalid">
                Você deve informar um CNPJ válido
              </span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <h4>Endereço da empresa</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="CEP" label-for="companyAddressCep">
            <b-form-input
              id="companyAddressCep"
              v-mask="'#####-###'"
              v-model="companyAddressCep"
              :class="{ 'is-invalid': v$.companyAddressCep.$error }"
              @change="searchCep('companyAddressName')"
            />
            <div class="invalid-feedback">
              <span v-if="v$.companyAddressCep.required.$invalid">
                Você deve informar um CEP
              </span>
              <span v-if="v$.companyAddressCep.minLength.$invalid">
                O CEP informado é inválido
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="8">
          <b-form-group label="Logradouro" label-for="companyAddressName">
            <b-form-input
              id="companyAddressName"
              v-model="companyAddressName"
              :class="{ 'is-invalid': v$.companyAddressName.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.companyAddressName.$invalid">
                Você deve informar um endereço
              </span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <h4>Documentação</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <file-chooser
            id="company-address-file"
            label="Comprovante de endereço"
            v-model="companyAddressFile"
            :url="companyAddressFileUrl"
            :disabled="saving"
            :thereIsError="
              v$.companyAddressFile.$error && v$.companyAddressFile.$invalid
            "
            errorMessage="O arquivo deve possuir um tamanho de até 10MB"
            only-documents
          />
        </b-col>
        <b-col cols="12" md="4">
          <file-chooser
            id="social-contract-file"
            label="Cartão CNPJ"
            v-model="socialContractFile"
            :url="socialContractFileUrl"
            :disabled="saving"
            :thereIsError="
              v$.socialContractFile.$error && v$.socialContractFile.$invalid
            "
            errorMessage="O arquivo deve possuir um tamanho de até 10MB"
            only-documents
          />
        </b-col>
        <b-col cols="12" md="4">
          <file-chooser
            id="partner-identification-file"
            label="Documento de identificação do responsável"
            v-model="partnerIdentificationFile"
            :url="partnerIdentificationFileUrl"
            :disabled="saving"
            :thereIsError="
              v$.partnerIdentificationFile.$error &&
              v$.partnerIdentificationFile.$invalid
            "
            errorMessage="O arquivo deve possuir um tamanho de até 10MB"
            only-documents
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <h4>Pix da empresa</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group
            label="Tipo da chave pix"
            label-for="franchisePixTypeKey"
          >
            <v-select
              id="franchisePixTypeKey"
              v-model="franchisePixTypeKey"
              :options="pixKeyTypes"
              dir="ltr"
              :class="getSelectErrorClass(v$.franchisePixTypeKey.$error)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span v-if="v$.franchisePixTypeKey.required.$invalid">
                Você deve selecionar um tipo de chave pix
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="8">
          <b-form-group label="Chave pix" label-for="franchisePix">
            <b-form-input
              id="franchisePix"
              v-model="franchisePix"
              :class="{ 'is-invalid': v$.franchisePix.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.franchisePix.$invalid">
                Você deve informar uma chave pix
              </span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <h4>Informações pessoais do representante da empresa</h4>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12" md="6">
          <file-chooser
            id="avatar-file"
            label="Foto"
            v-model="pictureFile"
            :url="pictureFileUrl"
            :disabled="saving"
            :thereIsError="v$.pictureFile.$error && v$.pictureFile.$invalid"
            errorMessage="Arquivo deve ter menos de 10mb"
            only-images
          />
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Celular" label-for="consultant-cellphone">
            <vue-tel-input
              id="consultant-cellphone"
              v-model="cellPhone"
              :class="{ 'is-invalid': v$.cellPhone.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.cellPhone.$invalid">
                Você deve informar um telefone válido
              </span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12" md="6">
          <b-form-group label="Instagram" label-for="personalInstagram">
            <b-form-input
              id="personalInstagram"
              v-model="personalInstagram"
              type="url"
              placeholder="Apenas @username (ex: @futuroconsultoriafinanceira)"
              :class="{
                'is-invalid': v$.personalInstagram.$error && personalInstagram,
              }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.personalInstagram.$invalid && personalInstagram">
                Insira apenas o @username
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="LinkedIn" label-for="personalLinkedin">
            <b-form-input
              id="personalLinkedin"
              v-model="personalLinkedin"
              placeholder="Username"
              type="url"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12" md="4">
          <b-form-group label="CEP" label-for="personalAddressCep">
            <b-form-input
              id="personalAddressCep"
              v-mask="'#####-###'"
              v-model="personalAddressCep"
              :class="{ 'is-invalid': v$.personalAddressCep.$error }"
              @change="searchCep('personalAddressName')"
            />
            <div class="invalid-feedback">
              <span v-if="v$.personalAddressCep.required.$invalid">
                Você deve informar um CEP
              </span>
              <span v-if="v$.personalAddressCep.minLength.$invalid">
                O CEP informado é inválido
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="8">
          <b-form-group label="Logradouro" label-for="personalAddressName">
            <b-form-input
              id="personalAddressName"
              v-model="personalAddressName"
              :class="{ 'is-invalid': v$.personalAddressName.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.personalAddressName.$invalid">
                Você deve informar um endereço
              </span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col cols="12">
          <b-row class="justify-content-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'contracts-list' }"
              :disabled="saving"
            >
              Cancelar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mx-1"
              :disabled="saving"
              @click.prevent="save"
            >
              <b-spinner v-if="saving" small />
              <feather-icon v-else icon="SaveIcon" class="mr-50" />
              <span>
                {{ saving ? "Salvando..." : "Salvar" }}
              </span>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import _ from "lodash";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BSpinner,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BAvatar,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, minLength } from "@vuelidate/validators";
import { onlyDigits } from "../../../helpers/converters";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import cnpjIsValid from "../../../validators/cnpj";
import maxFileSize from "../../../validators/file";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import FileChooser from "@/modules/shared/components/FileChooser";
import { getVueSelectErrorClass } from "@/helpers/validators";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BAvatar,
    vSelect,
    BFormFile,
    DynamicSelect,
    FileChooser,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), v$: useVuelidate(), skin };
  },
  data() {
    return {
      saving: false,
      id: undefined,
      franchiseName: undefined,
      leaderFranchiseName: undefined,
      companyName: undefined,
      cnpj: undefined,
      email: undefined,
      companyAddressCep: undefined,
      companyAddressName: undefined,
      franchisePixTypeKey: undefined,
      franchisePix: undefined,
      companyAddressFile: undefined,
      companyAddressFileUrl: undefined,
      socialContractFile: undefined,
      socialContractFileUrl: undefined,
      partnerIdentificationFile: undefined,
      partnerIdentificationFileUrl: undefined,
      personalAddressCep: undefined,
      personalAddressName: undefined,
      personalInstagram: undefined,
      personalLinkedin: undefined,
      pictureFile: undefined,
      pictureFileUrl: undefined,
      cellPhone: undefined,
      loading: {
        banks: false,
        cep: false,
      },
    };
  },
  validations() {
    return {
      franchiseName: {},
      leaderFranchiseName: {},
      companyName: { required },
      cnpj: {
        required,
        valid(value) {
          return cnpjIsValid(value);
        },
      },
      companyAddressCep: {
        required,
        minLength: minLength(0),
      },
      companyAddressName: { required },
      franchisePixTypeKey: { required },
      franchisePix: { required },
      companyAddressFile: {
        required: requiredIf(() => !this.companyAddressFileUrl),
        valid(file) {
            return (!file && this.companyAddressFileUrl) || maxFileSize(file.size)
        }
      },
      companyAddressFileUrl: {},
      socialContractFile: {
        required: requiredIf(() => !this.socialContractFileUrl),
        valid(file) {
            return (!file && this.socialContractFileUrl) || maxFileSize(file.size)
        }
      },
      socialContractFileUrl: {},
      partnerIdentificationFile: {
        required: requiredIf(() => !this.partnerIdentificationFileUrl),
        valid(file) {
            return (!file && this.partnerIdentificationFileUrl) || maxFileSize(file.size)
        }
      },
      partnerIdentificationFileUrl: {},
      personalAddressCep: {
        required,
        minLength: minLength(0),
      },
      personalAddressName: { required },
      personalInstagram: {
        regex(value) {
          if (value) {
            const pattern = /^[a-zA-Z0-9_.@]+$/;
            return pattern.test(value) && value.includes("@");
          }
          return true;
        },
      },
      pictureFile: {
        required: requiredIf(() => !this.pictureFileUrl),
        valid(file) {
            return (!file && this.pictureFileUrl) || maxFileSize(file.size)
        }
      },
      pictureFileUrl: {},
      cellPhone: {
        minLength(value) {
          return value.length >= 9;
        },
        maxLength(value) {
          return value.length <= 20;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      banks: sharedTypes.BANKS,
      pixKeyTypes: sharedTypes.PIX_KEY_TYPES,
    }),
  },
  mounted() {
    this.getPixKeyTypes();
    this.getConsultant(this.$route.params.id)
      .then((resp) => {
        const {
          id,
          franchise_fantasy_name,
          leader_franchise_name,
          company_name,
          franchise_cnpj,
          email,
          company_address_cep,
          company_address,
          personal_address_cep,
          personal_address,
          franchise_pix_type_key,
          franchise_pix,
          cell_phone,
          company_address_file_url,
          social_contract_file_url,
          partner_identification_file_url,
          picture_file_url,
          instagram,
          linkedin,
        } = resp.data;
        this.id = id;
        this.franchiseName = franchise_fantasy_name;
        this.leaderFranchiseName = leader_franchise_name;
        this.companyName = company_name;
        this.cnpj = franchise_cnpj;
        this.email = email;
        this.companyAddressCep = company_address_cep;
        this.companyAddressName = company_address;
        this.personalAddressCep = personal_address_cep;
        this.personalAddressName = personal_address;
        this.franchisePixTypeKey = franchise_pix_type_key;
        this.franchisePix = franchise_pix;
        this.cellPhone = cell_phone;
        this.companyAddressFileUrl = company_address_file_url;
        this.personalInstagram = instagram !== 'null' ? instagram : '';
        this.personalLinkedin = linkedin  !== 'null' ? linkedin : '';
        (this.socialContractFileUrl = social_contract_file_url),
          (this.partnerIdentificationFileUrl = partner_identification_file_url);
        this.pictureFileUrl = picture_file_url;
      })
      .catch((err) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as informações do consultor. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    if (this.$route.params.company_data_pendency) {
      if (!this.$swal.isVisible()) {
        this.fillBlockMessage();
      } else {
        this.$swal.update({
          didClose: () => this.fillBlockMessage(),
        });
      }
    }
    if (this.$route.params.company_contract_pendency) {
      if (!this.$swal.isVisible()) {
        this.signBlockMessage();
      } else {
        this.$swal.update({
          didClose: () => this.signBlockMessage(),
        });
      }
    }
  },
  methods: {
    ...mapActions({
      saveConsultant: types.SAVE_CONSULTANT_COMPANY_DATA,
      getConsultant: types.GET_CONSULTANT,
      searchCepAddress: types.GET_CEP,
      getBanks: sharedTypes.GET_BANKS,
      getPixKeyTypes: sharedTypes.GET_PIX_KEY_TYPES,
    }),
    searchCep(type) {
      let cep = this.companyAddressCep;
      if (type === "personalAddressName") {
        cep = this.personalAddressCep;
      }
      if (cep.length === 9) {
        this.loading.cep = true;
        this.searchCepAddress(onlyDigits(cep))
          .then((response) => {
            if (type === "companyAddressName") {
              this.companyAddressName = `${response.data.logradouro}, ${response.data.bairro}, ${response.data.localidade} - ${response.data.uf}`;
            } else if (type === "personalAddressName") {
              this.personalAddressName = `${response.data.logradouro}, ${response.data.bairro}, ${response.data.localidade} - ${response.data.uf}`;
            }
          })
          .catch((error) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao obter o endereço do CEP informado",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading.cep = false;
          });
      }
    },
    async save() {
      let d = new Date();
      d.setHours(d.getHours() - 3);
      const payload = {
        id: this.id,
        franchise_fantasy_name: this.franchiseName,
        company_name: this.companyName,
        franchise_cnpj: onlyDigits(this.cnpj),
        company_address_cep: this.companyAddressCep,
        company_address: this.companyAddressName,
        franchise_pix_type_key: this.franchisePixTypeKey,
        franchise_pix: this.franchisePix,
        company_data_updated_at: d
          .toISOString()
          .replace("T", " ")
          .replace("Z", "")
          .slice(0, 19),
        personal_address_cep: this.personalAddressCep,
        personal_address: this.personalAddressName,
        company_address_file: this.companyAddressFile,
        company_address_file_url: this.companyAddressFileUrl,
        social_contract_file: this.socialContractFile,
        social_contract_file_url: this.socialContractFileUrl,
        partner_identification_file: this.partnerIdentificationFile,
        partner_identification_file_url: this.partnerIdentificationFileUrl,
        picture_file: this.pictureFile,
        picture_file_url: this.pictureFileUrl,
        cell_phone: this.cellPhone,
        instagram: this.personalInstagram,
        linkedin: this.personalLinkedin,
      };

      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;

      const request = new FormData();
      for (let [key, value] of Object.entries(payload)) {
        if (value !== undefined && value !== "" && value !== "null") {
          request.append(key, value);
        }
      }

      this.saveConsultant(request)
        .then((response) => {
          this.$swal({
            title: "Contrato enviado para seu email pessoal!",
            text: `Você receberá o contrato para assinatura com os dados atualizados da empresa por e-mail.`,
            icon: "success",
            background: `${this.skin === "dark" ? "#283046" : ""}`,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-secondary ml-1",
              htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
            },
          });
        })
        .catch((err) => {
          if (err?.response?.status == 405) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (err?.response?.status == 413) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Tamanho do arquivo não pode exceder 10MB",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao salvar o consultor. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    signBlockMessage() {
      this.$swal({
        title: "Assine o contrato",
        text: `Você ainda não assinou o contrato enviado para o e-mail ${this.$route.params.user.email}. Realize a assinatura em até ${this.$route.params.user.days} dias para evitar a inativação do seu acesso.`,
        icon: "warning",
        showCancelButton: false,
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        confirmButtonText: "Continuar",
        customClass: {
          confirmButton: "btn btn-primary",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      });
    },
    fillBlockMessage() {
      this.$swal({
        title: "Preencha os dados",
        text: `Informe os dados da sua empresa e prossiga com a assinatura do contrato. Caso contrário, seu acesso será inativado em ${this.$route.params.user.days} dias.`,
        icon: "info",
        showCancelButton: false,
        confirmButtonText: "Continuar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      });
    },
  },
};
</script>
